import React from "react"
import { graphql, Link } from "gatsby"

import Layout, { zeusAltTheme } from "../../components/layout"
import ContactForm from "../../components/contactForm"
import SocialList from "../../components/socialList"
import styles from "./index.module.scss"

export const query = graphql`
  query {
    craft {
      liveScoping: entry(section: "liveScoping") {
        uri
      }
      entry(section: "contact") {
        title
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_contact_contact_Entry {
          alternateTitle
          contactForm {
            ... on Craft_contactForm_BlockType {
              description
            }
          }
        }
      }
      globalSets(handle: ["footer", "businessInformation"]) {
        ... on Craft_businessInformation_GlobalSet {
          phoneNumber
          generalEmailAddress
          newBusinessEmailAddress
          careersEmailAddress
        }
      }
    }
  }
`

const ContactPage = ({ data }) => {
  const bizInfo = data.craft.globalSets[0]
  const entry = data.craft.entry
  const seo = entry.seomatic
  const contactForm = entry.contactForm[0]
  const liveScopingUri = data.craft.liveScoping.uri
  let title

  if (entry.alternateTitle) {
    title = entry.alternateTitle
  } else {
    title = entry.title
  }

  return (
    <Layout seo={seo} theme={zeusAltTheme}>
      <div className="page-content-module">
        <div className="mb-20 wrapper">
          <h1 className="screen-reader-only">{entry.title}</h1>

          <div className="mb-6">
            <div className="columns">
              <div className="column is-12-mobile is-7-tablet is-5-widescreen is-offset-1-widescreen">
                <div className="mb-4">
                  <h2
                    className="h-2 h--cms-title"
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  ></h2>
                </div>
                <div
                  className="mb-2"
                  dangerouslySetInnerHTML={{
                    __html: contactForm.description,
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="columns is-multiline">
            <div
              className={`column is-12-mobile is-7-tablet is-5-widescreen is-offset-1-widescreen ${styles.formColumn}`}
            >
              <div className={styles.form}>
                <ContactForm />
              </div>
            </div>

            <div
              className={`column is-12-mobile is-4-tablet is-offset-1-tablet is-4-desktop is-offset-1-desktop is-4-widescreen is-offset-2-widescreen`}
            >
              <div className="mb-9">
                <h3 className="mb-1 body-large">General</h3>
                <a
                  className="link"
                  href={`mailto:${bizInfo.generalEmailAddress}`}
                >
                  {bizInfo.generalEmailAddress}
                </a>
              </div>
              <div className="mb-9">
                <h3 className="mb-1 body-large">Projects</h3>
                <a
                  className="link"
                  href={`mailto:${bizInfo.newBusinessEmailAddress}`}
                >
                  {bizInfo.newBusinessEmailAddress}
                </a>
              </div>
              <div className="mb-9">
                <h3 className="mb-1 body-large">Careers</h3>
                <a
                  className="link"
                  href={`mailto:${bizInfo.careersEmailAddress}`}
                >
                  {bizInfo.careersEmailAddress}
                </a>
              </div>
              <div className="mb-9">
                <h3 className="screen-reader-only">Phone Number</h3>
                {bizInfo.phoneNumber}
              </div>
              <div>
                <SocialList section="contact" />
              </div>
            </div>

            <div className="column is-12-mobile is-7-tablet is-5-widescreen is-offset-1-widescreen">
              <div className={styles.liveScoping}>
                <div className={styles.liveScopingHeading}>
                  <h3 className="mb-1 body-large-after-tablet fw-bold-until-tablet">
                    Have you heard about our Live Scoping sessions?
                  </h3>
                </div>
                <p className="mb-2">
                  A live scoping session is a free, no-strings-attached workshop
                  where we work with you to mutually understand a problem and
                  outline a course of action to solve it, together.
                </p>
                <p>
                  <Link to={`/${liveScopingUri}`} className="link">
                    Tell me more
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
